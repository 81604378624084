import React from 'react'

const Card = ({className , children}) => {
  return (
    <article className={`card ${className} ShanNiFont`}>
        {children}
    </article>
  )
}

export default Card