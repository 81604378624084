import Letter_A from '../audios/specialOne/Latter-A.mp3'
import Letter_B from '../audios/specialOne/Latter-B.mp3'
import Letter_C from '../audios/specialOne/Latter-C.mp3'
import Letter_D from '../audios/specialOne/Latter-D.mp3'
import Letter_E from '../audios/specialOne/Latter-E.mp3'
import Letter_F from '../audios/specialOne/Latter-F.mp3'
import Letter_G from '../audios/specialOne/Latter-G.mp3'

export const specialOneSource = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
    dD:Letter_D,
    eE:Letter_E,
    fF:Letter_F,
    gG:Letter_G,
}