import React, { useState, useEffect } from 'react';
import './vocabList.css';
import { FaSearch } from "react-icons/fa"

const VocabList = () => {
  const [vocabs, setVocabs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [describe,setDescribe] = useState(0);
  // Fetch vocabs data
  useEffect(() => {
    fetch('https://info.shannivocab.com/api/vocabs')
      .then(response => response.json())
      .then(data => setVocabs(data))
      .catch(error => console.error('Error:', error));
  }, []);



  // Filter vocabs based on search input
  const filteredVocabs = vocabs.filter((vocab) =>
    vocab.tai.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vocab.myan.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vocab.eng.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <main className="table">
      <section className="table_header">
        <a href="/">
          <h3 className="ShanNiFont">ၷႂၫမ်းတႆးꩫꧥင်း</h3>
        </a>
        <div className="input-group">
          <input className='ShanNiFont'
            type="search"
            placeholder="Search Data ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {/* <img src="../../images/search.png" alt="Search" /> */}
          <FaSearch/>
        </div>
      </section>

      <section className="table_body">
        <table id="data-table">
          <thead>
            <tr>
              <th className="ShanNiFont">မႆၫꩼ</th>
              <th className="ShanNiFont tai">တႆးꩫꧥင်း</th>
              <th className="ShanNiFont">ꧤႃꩬႃမၫꩫ်</th>
              <th className="ShanNiFont">English</th>
              <th className="ShanNiFont">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredVocabs.map((vocab, index) => (
              <>
              <tr key={index}>
                <td>{vocab.id}</td>
                <td className="ShanNiFont">{vocab.tai}</td>
                <td className="ShanNiFont">{vocab.myan}</td>
                <td>{vocab.eng}</td>
                <td>
                  <p className="status readmore" onClick={()=>setDescribe(!describe)}>more</p>
                </td>
              </tr>
              {describe>0&&<tr class="description-row">
                <td colspan="6" class="description ShanNiFont">{vocab.description}</td>
            </tr>}
              </>
            ))}
              
          </tbody>
        </table>
      </section>
    </main>
  );
};

export default VocabList;
