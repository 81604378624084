import Letter_A from '../audios/swuntThree/Latter-A.mp3'
import Letter_B from '../audios/swuntThree/Latter-B.mp3'
import Letter_C from '../audios/swuntThree/Latter-C.mp3'


export const swuntThreeSource = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
   
}