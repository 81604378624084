import Letter_A from '../audios/swuntTwo/Latter-A.mp3'
import Letter_B from '../audios/swuntTwo/Latter-B.mp3'
import Letter_C from '../audios/swuntTwo/Latter-C.mp3'



export const swuntTwoSource = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
  
}