import Letter_A from '../audios/specialTwo/Latter-A.mp3'
import Letter_B from '../audios/specialTwo/Latter-B.mp3'
import Letter_C from '../audios/specialTwo/Latter-C.mp3'
import Letter_D from '../audios/specialTwo/Latter-D.mp3'
import Letter_E from '../audios/specialTwo/Latter-E.mp3'

export const specialTwoSource = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
    dD:Letter_D,
    eE:Letter_E,
}