import Letter_A from '../audios/voice6Tone/Latter-A.mp3'
import Letter_B from '../audios/voice6Tone/Latter-B.mp3'
import Letter_C from '../audios/voice6Tone/Latter-C.mp3'
import Letter_D from '../audios/voice6Tone/Latter-D.mp3'
import Letter_E from '../audios/voice6Tone/Latter-E.mp3'
import Letter_F from '../audios/voice6Tone/Latter-F.mp3'

export const voice6ToneSources = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
    dD:Letter_D,
    eE:Letter_E,
    fF:Letter_F,
}