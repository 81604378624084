import Letter_A from '../audios/swuntOne/Latter-A.mp3'
import Letter_B from '../audios/swuntOne/Latter-B.mp3'
import Letter_C from '../audios/swuntOne/Latter-C.mp3'
import Letter_D from '../audios/swuntOne/Latter-D.mp3'


export const swuntOneSource = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
    dD:Letter_D,
   
}