import Letter_A from '../audios/specialThree/Latter-A.mp3'
import Letter_B from '../audios/specialThree/Latter-B.mp3'
import Letter_C from '../audios/specialThree/Latter-C.mp3'
import Special_Letter from '../audios/specialThree/Special-Three.mp3'

export const specialLetterThree = {
    a:Special_Letter
}
export const specialThreeSource = {
    aA : Letter_A,
    bB:Letter_B,
    cC:Letter_C,
    
}